import ryanGittlesonImg from '../../assets/images/team/ryan-gittleson.png';
import alvaroRodriguezImg from '../../assets/images/team/alvaro-rodriguez.png';
import iddoBentovImg from '../../assets/images/team/iddo-bentov.png';
import catalinDraguImg from '../../assets/images/team/catalin-dragu.png';
import jesusLanchasImg from '../../assets/images/team/jesus-lanchas.png';
import israelLozanoImg from '../../assets/images/team/israel-lozano.png';
import danielOrtegaImg from '../../assets/images/team/daniel-ortega.png';
import raulGuiuImg from '../../assets/images/team/raul-guiu.png';
import joseToro from '../../assets/images/team/jose-toro.png';
import jimBerry from '../../assets/images/team/jim-berry.png';
import davidOconnor from '../../assets/images/team/david-oconnor.png';
import sergioNavas from '../../assets/images/team/sergio-navas.png';
import angelNavarro from '../../assets/images/team/angel-navarro.png';
import javierBueno from '../../assets/images/team/javier-bueno.png';
import pedroRoson from '../../assets/images/team/pedro-roson.png';
import joseIgnacio from '../../assets/images/team/jose-ignacio.png';
import hamiltonCarmean from '../../assets/images/team/hamilton.png';
import fenandoVera from '../../assets/images/team/fernando.png';
import davidDominguez from '../../assets/images/team/davidd.png';

export const team = [{
  name: 'Ryan Gittleson',
  role: 'Co-Founder, Business Development',
  linkedinUrl: 'https://www.linkedin.com/in/ryan-gittleson-30093b4b/',
  profileImg: ryanGittlesonImg,
}, {
  name: 'Hamilton Carmean',
  role: 'Chief Financial Officer',
  linkedinUrl: 'https://www.linkedin.com/in/hamiltoncarmean/',
  profileImg: hamiltonCarmean,
}, {
  name: 'Jim Berry',
  role: 'Co-Founder and Lead Platform Engineer',
  linkedinUrl: 'https://www.linkedin.com/in/jimkberry/',
  profileImg: jimBerry,
}, {
  name: 'Álvaro Rodríguez',
  role: 'Senior Javascript Developer and Smart Contract Tech Lead',
  linkedinUrl: 'https://www.linkedin.com/in/alvaro-villalba/',
  profileImg: alvaroRodriguezImg,
}, {
  name: 'Iddo Bentov',
  role: 'Chief Cryptographer',
  profileImg: iddoBentovImg,
}, {
  name: `David O'Connor`,
  role: 'Head of Game Security',
  linkedinUrl: 'https://www.linkedin.com/in/david-m-oconnor/',
  profileImg: davidOconnor,
}, {
  name: 'Catalin Dragu',
  role: 'Art Director',
  linkedinUrl: 'https://www.linkedin.com/in/dragucatalin/',
  profileImg: catalinDraguImg,
}, {
  name: 'Jesús Lanchas',
  role: 'Platform Team Lead',
  linkedinUrl: 'https://www.linkedin.com/in/jesuslanchassampablo/',
  profileImg: jesusLanchasImg,
}, {
  name: 'Daniel Ortega',
  role: 'Senior Software Engineer and Backend Tech Lead',
  linkedinUrl: 'https://www.linkedin.com/in/olmaygti/',
  profileImg: danielOrtegaImg,
}, {
  name: 'Israel Lozano',
  role: 'QA Team Lead and Scrum Master',
  linkedinUrl: 'https://www.linkedin.com/in/isgarlo/',
  profileImg: israelLozanoImg,
}, {
  name: 'Raúl Guiu',
  role: 'Senior Back-End Developer',
  linkedinUrl: 'https://www.linkedin.com/in/rguiu/',
  profileImg: raulGuiuImg,
}, {
  name: 'José Toro',
  role: 'Games Team Lead',
  linkedinUrl: 'https://www.linkedin.com/in/joseantonioaguileratoro/',
  profileImg: joseToro,
}, {
  name: 'Sergio Navas',
  role: 'Front-End Developer',
  linkedinUrl: 'https://www.linkedin.com/in/sergio-manuel-navas-martin-92ab35157/',
  profileImg: sergioNavas,
}, {
  name: 'Ángel Navarro',
  role: 'Full-Stack Developer',
  linkedinUrl: 'https://www.linkedin.com/in/angelnavarrobaeza/',
  profileImg: angelNavarro,
}, {
  name: 'Javier Bueno',
  role: 'Full-Stack Developer',
  linkedinUrl: 'https://www.linkedin.com/in/javierbuenomarfil/',
  profileImg: javierBueno,
}, {
  name: 'Pedro Rosón',
  role: 'Senior Software Engineer',
  linkedinUrl: 'https://www.linkedin.com/in/pedroroson/',
  profileImg: pedroRoson,
}, {
  name: 'José Ignacio Damigo',
  role: 'Automation QA Engineer',
  linkedinUrl: 'https://www.linkedin.com/in/jose-ignacio-damigo-diaz-4608aa96',
  profileImg: joseIgnacio,
}, {
  name: 'Fernando Vera',
  role: 'Senior Software Engineer',
  linkedinUrl: 'https://www.linkedin.com/in/fernando-vera-lópez-6719a542/',
  profileImg: fenandoVera,
}, {
  name: 'David Domínguez',
  role: 'QA Engineer',
  linkedinUrl: 'https://www.linkedin.com/in/dominguezdavid/',
  profileImg: davidDominguez,
}]
