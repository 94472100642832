import React from 'react'
import { LinkedIn } from '../Icon'
import MSTable from '../HowToPlay/MSTable';
import * as s from './s.module.css'
import { team } from './data'
import Title from '../Title';

const Team = () => (
  <>
    <p className={s.teamParagraph}>The Virtue Poker team is comprised of talented computer scientists, accomplished industry veterans from the blockchain technology and poker communities and the world’s top poker players. The team includes a former NASA engineer and a development and product team averaging over 15 years of experience in each of their respective domains.</p>
    <p className={s.teamParagraph}>The advisory board of Virtue Poker includes Joe Lubin, one of the co-founders of the Ethereum blockchain, and Damian Sokol, the former CEO of PokerStrategy, the largest online poker strategy website . And the Virtue Poker Team pros, Phil Ivey, Dan Colman and Brian Rast, own fifteen World Series of Poker bracelets and over $70 million in winnings.</p>
    <p className={s.teamSubtitle}>Team</p>
    <div className={s.teamContainer}>
      {team.map((teamMember, idx) => (
        <div key={idx} className={s.teamMemberContainer}>
          <div className={s.socialContainer}>
            <img className={s.teamMemberImg} src={teamMember.profileImg} />
            <a className={s.teamMemberLinkedInLink} href={teamMember.linkedinUrl} target="_blank">
              <LinkedIn />
            </a>
          </div>
          <div className={s.infoContainer}>
            <span className={s.teamMemberName}>{teamMember.name}</span>
            <span>{teamMember.role}</span>
          </div>
        </div>
      ))}
    </div>
  </>
)

const whoWeAreContentData = [{
  title: "Who We Are",
  navigationreferenceid: '#who-we-are',
  customComponent: () =><Team />,
}]

export const whoWeAreNavigationData = [{
  title: "Who We Are",
  id: 'who-we-are'
}]

class WhoWeAre extends React.Component {
  render() {
    return (
      <section className={s.sectionContainer}>
        <div className={s.sectionContent}>
          <Title>Who We Are</Title>
          <MSTable contentData={whoWeAreContentData} navigationData={whoWeAreNavigationData} />
        </div>
      </section>
    )
  }
}

export default WhoWeAre;
