import React from "react";
import Layout from "../layout/default";
import Seo from "../components/seo";
import WhoWeAre from '../components/WhoWeAre';

const WhoWeArePage = ({ location, pageContext }) => (
    <Layout location={location} dynamicDefaultPaths={pageContext.dynamicDefaultPaths}>
        <Seo title="Who We Are" />
        <WhoWeAre />
    </Layout>
)

export default WhoWeArePage;
