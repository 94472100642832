// extracted by mini-css-extract-plugin
export var infoContainer = "s-module--infoContainer--9b7aa";
export var logoText = "s-module--logoText--c98e4";
export var sectionContainer = "s-module--sectionContainer--6ef0f";
export var sectionContent = "s-module--sectionContent--86d08";
export var socialContainer = "s-module--socialContainer--55e42";
export var teamContainer = "s-module--teamContainer--4172b";
export var teamMemberContainer = "s-module--teamMemberContainer--d42c7";
export var teamMemberImg = "s-module--teamMemberImg--e3018";
export var teamMemberLinkedInLink = "s-module--teamMemberLinkedInLink--8065f";
export var teamMemberName = "s-module--teamMemberName--3d0e0";
export var teamParagraph = "s-module--teamParagraph--24694";
export var teamSubtitle = "s-module--teamSubtitle--a0148";